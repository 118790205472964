<div class="container-fluid products" id="products">
  <div class="container">
    <div class="row">
      <div class="col">
        <br>
        <h3>Products</h3>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <img class="img-fluid" src="./assets/ab_Outside-01-crop.png" width="400" height="auto">
          <br><br>
          <h5>While social media in its current form does a great job helping us discover people, buisnesses and events from across the world, they do a poor job connecting us with those nearby.</h5>
          <br><br>
          <h5>Outside aims to fix this problem by providing you with the latest information on your current location.</h5>
          <br><br>
          <a href="https://play.google.com/store/apps/details?id=com.blurryrobot.outside">
            <img class="img-fluid" src="./assets/google-play-badge.png" width="500"></a>
      </div>
      <div class="col">
        <img class="img-fluid" src="./assets/Phone Screenshot 2.jpg">
      </div>
    </div>
    <div class="row">
    </div>
  </div>
</div>
