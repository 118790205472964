<nav class="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
  <a class="navbar-brand" [routerLink]="" (click)="scroll('home')">
  blurryRobot
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="" (click)="scroll('home')">Home<span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="" (click)="scroll('products')">Products</a>
      </li>
      <li class="nav-item">
	<a class="nav-link" [routerLink]="" (click)="scroll('services')">Services</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  [routerLink]="" (click)="scroll('about')">About</a>
      </li>
      <li class="nav-item">
	      <a class="nav-link" [routerLink]="" (click)="scroll('contact-us')">Contact</a>
      </li>
    </ul>
  </div>
</nav>
