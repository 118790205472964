<div class="container-fluid contact-us" id="contact-us">
  <div class="container">
    <div class="row">
      <div class="col">
        <br>
        <h3>Contact Us</h3>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul>
          <li>
            <h6>Have an app idea?</h6>
          </li>
          <li>
            <h6>Need help with your company website?</h6>
          </li>
          <li>
            <h6>Want to make a game?</h6>
          </li>
          <li>
            <h6>Want to tell us how awesome we are?</h6>
          </li>
        </ul>
        <br>
        <br>
      </div>
      <div class="col">
        <div class="row">
          <h5>Send us your ❤️ at:</h5>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <span>
                <img class="img-fluid" width="30" height="auto" src="./assets/email-24px.svg">
                &nbsp;<a class="btn btn-link" href="mailto:rafael@blurryrobot.net?subject=feedback">rafael@blurryrobot.net</a>
              </span>
            </div>
            <div class="row">
              <span>
                <img class="img-fluid" width="30" height="auto" src="./assets/call-24px.svg">
                &nbsp;<a class="btn btn-link" href="tel:787-367-5519">787-367-5519</a></span>
            </div>
          </div>
        </div>
        <br><br>

      </div>
    </div>
  </div>
</div>
