<div class="container-fluid services" id="services">
	<div class="container">
		<div class="row">
			<div class="col">
				<br>
				<h3>Services</h3>
				<br>
			</div>
		</div>
		<div class="row">
			<div class="col-sm justify-content-center text-center">
				<img class="img-fluid" src="./assets/business_center-24px.svg" width="100" height="auto">
				<br>
				<h6>Consulting</h6>
			</div>
			<div class="col-sm justify-content-center text-center">
				<img class="img-fluid" src="./assets/sports_esports-24px.svg" width="100" height="auto">
				<br>
				<h6>Video Game Development</h6>
			</div>
			<div class="col-sm justify-content-center text-center">
				<img class="img-fluid" src="./assets/smartphone-24px.svg" width="100" height="auto">
				<br>
				<h6>Mobile App Development</h6>
			</div>
			<div class="col-sm justify-content-center text-center">
				<img class="img-fluid" src="./assets/public-24px.svg" width="100" height="auto">
				<br>
				<h6>Web Development</h6>
				<br><br>
			</div>
		</div>
	</div>
</div>
