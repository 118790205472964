<div class="container-fluid" id="about">
<div class="container">
<div class="row">
  <div class="col">
    <br>
    <h3>About</h3>
    <br>
  </div>
</div>
<div class="row">
  <div class="col"></div>
  <div class="col-md-4 justify-content-center text-center">
    <img class="img-fluid" width="400" height="auto" src="./assets/G_blurryRobot-02.png">
    <br><br>
    <h6><b>Blurryrobot corp</b> is a technology company founded by <b>CEO Rafael Valle in 2020.</b> It develops, supports, licenses, and sells computer software and related services.
    <br><br>
</h6>
  </div>
  <div class="col"></div>
</div>
</div>
</div>
