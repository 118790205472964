<div class="jumbotron" id="home">
	<div class="row">
		<div class="col justify-content-center text-center">
      <br>
		<img class="img-fluid" src="./assets/G_blurryRobot-01-robo.png" width="50%" height="auto"  loading="lazy">
		<br><p></p>
		</div>
		<div class="col-sm-6 text-center">
			<br>
		<h1 class="display-3">We make software so easy robots could use it.</h1>
		<br><br><br>
		<h3><small class="text-muted"> Have an idea? See how we can help.</small></h3>
		<br><br>
		<a class="btn btn-info" href="mailto:rafael@blurryrobot.net?subject=feedback">Contact Us</a>
		<br><p></p>
	</div>
</div>
</div>
